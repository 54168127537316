.animated-viewer-section {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.animated-viewer-section-1 {
  opacity: 0;
  transform: scale(1.4);
}

.animated-viewer-intro {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60%;
  background-color: rgba(255,255,255,.9);
  padding: 1rem;
  display: flex;
  align-items: center;
  left: 100%;
}

.animated-viewer-intro h4 {
  font-size: .8rem;
}

.animated-viewer-intro h5 {
  font-size: 1rem;
  margin-top: .8rem;
}

.animated-viewer-section-2 {
  opacity: 0;
}

.animated-viewer-agent {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 85%;
  transform: translate(-50%, -50%);
  opacity: 0;
  background-color: rgba(255,255,255,.9);
}

.animated-viewer-agent h5 {
  font-size: .9rem;
  margin-top: .8rem;
  margin-bottom: .5rem;
}

.animated-viewer-avatar {
  width: 24%;
  padding-top: 24%;
  border-radius: 50%;
  overflow: hidden;;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: -12% auto 0 auto;
  position: relative;
  z-index: 1;
}

.animated-viewer-agent-body {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: .5rem;
  transform: rotateX(90deg);
}

.animated-viewer-agent-body p,
.animated-viewer-agent-body h5 {
  margin-top: 0;
  margin-bottom: .1rem;
  text-align: center;
  font-size: .6rem;
}

.animated-viewer-agent-body h5 {
  font-size: .8rem;
}

.animated-viewer-agent-body p.agent-phone {
  border-top: solid 1px #333;
  border-bottom: solid 1px #333;
  margin: 0;
  padding: .1rem .5rem;
}

.animated-viewer-active  .animated-viewer-section-0 {
  animation: animated-viewer-fadein 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.animated-viewer-active  .animated-viewer-section-1 {
  animation: animated-viewer-fadeout 1.5s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.animated-viewer-active  .animated-viewer-intro {
  animation: animated-viewer-intro-move 2s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
}

.animated-viewer-active  .animated-viewer-section-2 {
  animation: animated-viewer-fadein-out 6s;
  animation-delay: 4.5s;
  animation-fill-mode: forwards;
}

.animated-viewer-active  .animated-viewer-agent {
  animation: animated-viewer-agent 1s;
  animation-delay: 5.5s;
  animation-fill-mode: forwards;
}

.animated-viewer-active  .animated-viewer-agent-body {
  animation: animated-viewer-agent-body 1s;
  animation-delay: 5.5s;
  animation-fill-mode: forwards;
}

.animated-viewer-active  .animated-viewer-avatar-effect2 {
  animation: animated-viewer-avatar-effect2 1s;
  animation-delay: 10s;
  animation-fill-mode: forwards;
}

@keyframes animated-viewer-fadein {
  0% { transform: scale(1); opacity: 1;}
  100% { transform: scale(1.4); opacity: 0;}
}

@keyframes animated-viewer-fadeout {
  0% { transform: scale(1.4); opacity: 0;}
  100% { transform: scale(1); opacity: 1;}
}

@keyframes animated-viewer-intro-move {
  0% { left: 100%; }
  30% { left: 40%; }
  70% { left: 40%; }
  100% { left: 100%; }
}

@keyframes animated-viewer-fadein-out {
  0% { transform: scale(1); opacity: 0;}
  50% { transform: scale(1.4); opacity: 1;}
  100% { transform: scale(1); opacity: 1;}
}

@keyframes animated-viewer-agent {
  0% { opacity: 0;}
  100% { opacity: 1;}
}

@keyframes animated-viewer-agent-body {
  0% { opacity: 0; transform: rotateX(90deg); }
  100% { opacity: 1; transform: rotateX(0deg);}
}

@keyframes animated-viewer-avatar-effect2 {
  0% { opacity: 1; width: 24%; padding-top:24%; margin-top: -12%; }
  100% { opacity: 0; width: 0; padding-top: 0; margin-top: 0; }
}
