.navbar {
  display: flex;
  align-items: center;
  font-size: .8rem;
}

.navbar > li > a {
  color: #fff;
  height: 2.8rem;
  display: inline-flex;
  align-items: center;
  padding-left: .7rem;
  padding-right: .7rem;
  position: relative;
}

/* .navbar > li {
  position: relative;
} */

.has-child {}

.navbar > li.has-child:hover > a:after {
  content: '';
  border: solid .3rem transparent;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}


.navbar > li.has-child > a >span:last-child {
  transition: transform .2s;
}

.navbar > li.has-child:hover > a > span:last-child {
  transform: rotate(180deg);
}

.navbar > li.has-child:hover > .submenu {
  display: grid;
}

.submenu {
  display: none;
  position: absolute;
  left: 0;
  overflow-x: auto;
  max-width: 95vw;
  background-color: #fff;
  z-index: 1000;
  padding: 2rem;
  gap: 1rem;
  /* grid-template-columns: repeat(3, 1fr); */
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
  border-radius: 0 0 .4rem .4rem;
}

.submenu > li > a {
  white-space: nowrap;
  display: flex;
  border-radius: 0.2rem;
  background-color: #f2f5f7;
  padding: 0.9rem 1.4rem;
}

.item {
  display: flex;
  max-width: 18rem;
  overflow: hidden;
}

.item >i {
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  background-repeat: no-repeat;
}

.item b {
  font-size: 1rem;
}

.item span {
  color: #6f7485;
  font-size: .7rem;
}
