.combine-autocomplete-custom .MuiAutocomplete-listbox{
  max-height: inherit!important;
}

.combine-autocomplete-custom .MuiPaper-root {
  border-radius: 0!important;
}

.combine-autocomplete-custom [role='presentation'].MuiAutocomplete-popper {
  width: auto!important;
}
