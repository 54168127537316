.custom-popup-wrapper .mapboxgl-popup-content {
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0,0,0,.2);
}

.custom-popup-wrapper .mapboxgl-popup-content .mapboxgl-popup-close-button {
  padding-right: .2rem;
  padding-left: .3rem;
  border-radius: 0 0 0 .3rem;
}
